import React, { useEffect, useState } from 'react';

// import External Functions
import { formatLocalDate, convertMinutesToTimeStr } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

// import Internal Functions
import { translateStatus, translateType } from '../../../../utils/ReportUtil';

// import External Components
import { Table } from '@optimuminterfaces/revex-react-components/dist/components';

// import Models
import { Employee } from '../../../../models/Employee';
import { Report } from '../../../../models/Report';

import styles from './Show.module.scss';

interface ShowProps {
    className?: string, 
    report: Report, 
    employees?: Employee[]
	Container?: any
};

const sortMonths = (a: any, b: any) => {
    const monthOrder = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTUBER', 'NOVEMBER', 'DECEMBER'];
    return monthOrder.indexOf(a) - monthOrder.indexOf(b);
};

const Show = ({ 
    className, 
    report, 
    employees, 
    Container='div' 
} : ShowProps) => {
    
    useEffect(() => {
        if(!!employees){
            setEmployeesMap(Object.assign({}, ...employees.map((x) => ({[x.id]: x}))));
        }
    }, [employees]);

    const [employeesMap, setEmployeesMap] = useState();

    return (
        <Container className={`${className || ''} ${styles['report-show-rt']}`.trim()}>
            <dl className={styles['dl-side-by-side']}>
                <dt>{t('GENERIC.TEXT.NAME')}:</dt>
                <dd>{report.name}</dd>

                <dt>{t('GENERIC.TEXT.PERIOD')}:</dt>
                <dd>
                    {report.period.split(' ').map((element: string, idx: number) => {
                        return formatLocalDate(element, true, 'YYYY-MM-DD', undefined) + (idx === 0 ? ' até ' : '');
                    })}
                </dd>

                <dt>{t('GENERIC.TEXT.STATUS')}:</dt>
                <dd>{translateStatus(report.status)}</dd>

                <dt>{t('GENERIC.TEXT.TYPE')}:</dt>
                <dd>{translateType(report.type)}</dd>

                <dt>{t('CABLE.REPORT.TEXT.MADE_IN')}:</dt>
                <dd>{formatLocalDate(report.created, true, 'yyyy-MM-DDTHH:mm:ss.sss','DD/MM/yyyy HH:mm:ss')}</dd>
            </dl>
            
            {report.type === 'HOUR_BANK_BALANCE_DIVERGENCES' && 
                <dl>
                    <dt style={{'fontWeight': 'bold'}}>{t('GENERIC.TEXT.ADDITIONAL_DATA')}:</dt>
                    <dd className={`${styles['margin-0']}`}>
                        <Table defaul responsible>
                            <Table.Header>
                                <Table.HeaderItem>
                                    <Table.HeaderItem.Element>{t('GENERIC.TEXT.NAME')}</Table.HeaderItem.Element>
                                    <Table.HeaderItem.Element>{t('GENERIC.TEXT.CYCLE')}</Table.HeaderItem.Element>
                                    <Table.HeaderItem.Element>{t('CABLE.HOUR_BANK_BALANCES.REPORT_HOUR_BANK_BALANCE_DIVERGENCES.CYCLE_START')}</Table.HeaderItem.Element>
                                    <Table.HeaderItem.Element>{t('CABLE.HOUR_BANK_BALANCES.REPORT_HOUR_BANK_BALANCE_DIVERGENCES.CYCLE_END')}</Table.HeaderItem.Element>
                                    <Table.HeaderItem.Element>{t('CABLE.HOUR_BANK_BALANCES.REPORT_HOUR_BANK_BALANCE_DIVERGENCES.HOUR_BANK_VALUE')}</Table.HeaderItem.Element>
                                    <Table.HeaderItem.Element>{t('CABLE.HOUR_BANK_BALANCES.REPORT_HOUR_BANK_BALANCE_DIVERGENCES.POINT_REGISTRATION_VALUE')}</Table.HeaderItem.Element>
                                </Table.HeaderItem>
                            </Table.Header>
                            <Table.Body>
                                {!!Object.keys(report.additionalData) && 
                                    Object.keys(report.additionalData).map(employeeId => (
                                        <>
                                            <Table.BodyItem key={employeeId}>
                                                <Table.BodyItem.Element
                                                    rowSpan={Object.keys(report.additionalData[employeeId]).length +1}>
                                                    {employeesMap?.[employeeId]['name']}
                                                </Table.BodyItem.Element>
                                            </Table.BodyItem>

                                            {Object.keys(report.additionalData[employeeId]).sort(sortMonths).map(month => (
                                                <Table.BodyItem>
                                                    <Table.BodyItem.Element>{t(`GENERIC.MONTHS.${month}`)}</Table.BodyItem.Element>
                                                    <Table.BodyItem.Element>{formatLocalDate(report.additionalData[employeeId][month]['startDate'], true, 'yyyy-MM-DD', 'DD/MM/yyyy')}</Table.BodyItem.Element>
                                                    <Table.BodyItem.Element>{formatLocalDate(report.additionalData[employeeId][month]['endDate'], true, 'yyyy-MM-DD', 'DD/MM/yyyy')}</Table.BodyItem.Element>
                                                    <Table.BodyItem.Element>{convertMinutesToTimeStr(report.additionalData[employeeId][month]['hourBankBalanceValue'])}</Table.BodyItem.Element>
                                                    <Table.BodyItem.Element>{convertMinutesToTimeStr(report.additionalData[employeeId][month]['pointRegistrationValue'])}</Table.BodyItem.Element>
                                                </Table.BodyItem>
                                            ))}
                                        </>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </dd>
                </dl>
            }
        </Container>
    );
};

export default Show;
