import API from './core';

export const fetchAllReports = async (params: any) => {
	try{
		return API.get('/rest/points/reports', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postReport = async (params?: any) => {
	try{
		return API.post('/rest/points/reports', JSON.stringify(params), { headers: {'Content-Type': 'application/json'} })
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const downloadReport = async (id: any, params?: any) => {
	try{
		return API.get('/rest/points/reports/download/'+id,{ params: params, headers: { 'Content-Type': 'application/json' }, 
		responseType: 'arraybuffer' })
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}