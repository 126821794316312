import React, { useEffect, useState } from 'react';

// import External Components
import { Button, Input, Row, Select, DualList, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

// import External Functions
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

// import Internal Functions
import { postReport } from '../../../../services/Report.services';
import { calculateSelectMonth, calculateSelectYear } from '../../../../utils/SelectUtil';

// import Internal Models
import { Employee } from '../../../../models/Employee';

import styles from './New.module.scss';

const buildDualListOptions = (employees: Employee[], employeesSelected?: any[]) => {
	let dualList: { 
		key: string; 
		selected: boolean; 
		values: any[]; 
	}[] = [];

	const employeesSelectedSet = (employeesSelected || [])
		.reduce((set, { key }) => {
			set.add(key)
			return set;
		}, new Set());

	employees.forEach(employee => {
		dualList.push({
			key: employee.id, 
			selected: (employeesSelectedSet.has(employee.id)), 
			values: [employee.name,employee.occupation?.name]
		});
	});
	
	return dualList;
};

interface NewProps{
	className?: String, 
	reportType: String, 
	cancelAction?: Function,
	saveBeforeAction?: Function,
	employees?: Employee[], 
	handleChanges?: Function, 
	Container?: any
};

const New = ({ 
	className,
	reportType, 
	cancelAction, 
	saveBeforeAction, 
	employees, 
	handleChanges, 
	Container = 'div'
}: NewProps) => {
	const years = calculateSelectYear();
	const months = calculateSelectMonth();

	let currentMonth = months.filter(m => m.selected==true)[0];

	const [name, setName] = useState<string>('');
	const [listYears, setListYears] = useState<any[]>(years);
	const [listMonths, setListMonths] = useState<any[]>(months);
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [yearValue, setYearValue] = useState<any>(years[3]);
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);

	// DualList component
	const [employeesDualList, setEmployeesDualList] = useState<any[]>([]);
	const [employeesSelected, setEmployeesSelected] = useState<any[]>([]);

	// Alert messages
	const [toastAlert, setToastAlert] = useState<{ show: boolean, message: string, danger: boolean }>({ show: false, message: '', danger: false });

	useEffect(() => {
		setEmployeesDualList((!!employees ? buildDualListOptions(employees, employeesSelected) : []));
	}, [employees, employeesSelected]);

	const changeSelectMonth = (key: string) => {
        let months = [...listMonths];

        for (var i = 0; i < months.length; i++) {
            if (months[i].key === key) {
                months[i].selected = true;
                setMonthValue(months[i]);
            } else {
                months[i].selected = false;
            }
        }

        setListMonths(months);
    };

	const changeSelectYear = (key: string) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i]);
            } else {
                years[i].selected = false;
            }
        }

        setListYears(years);
    };

	const saveReport = async () => {
        if(!name || name.trim()===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.INFORM_NAME'),show: true});
			return;
		}

		if(!yearValue){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.REPORT.TEXT.YEAR_INFORMED'),show: true});
			return;
		}
		
		if(!monthValue){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.REPORT.TEXT.PERIOD_INFORMED'),show: true});
			return;
		}

		if(reportType === 'hourBankBalances' && (!employeesSelected || employeesSelected?.length <= 0)){
			setToastAlert({ ...toastAlert, danger: true, message: 'Os colaboradores devem ser selecionados', show: true });
			return;
		}

		setLoadingButtonSave(true);

		try{
			const jsonReturned = await postReport({
				name: name,
				year: yearValue.key,
				month: monthValue.key,
				type: reportType, 
				employeesId: (employeesSelected?.map(({ key }:{ key: String }) => (key)))
			});

			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonSave(false);
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					setTimeout(() => { 
						setLoadingButtonSave(false);
						if(typeof saveBeforeAction === 'function'){
							saveBeforeAction();
						}
					}, 3000);
				}
			}
		}catch(error){
			console.log(error);
		}
        
    }

	return(
		<Container className={`${(className || '')} ${styles['report-new-rt']}`.trim()}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Input type="text" fullWidth
						title={t('CABLE.REPORT.TEXT.REPORT_NAME')}
						name="reportName"
						value={name}
						handleChange={(event: React.ChangeEvent<HTMLInputElement>) => { setName(event.target.value) }} />
				</Row.Item>
				
				<Row.Item sm={6} md={6} lg={6}>
					<Select medium
						fullWidth
						name="selectMonth"
						value={monthValue.value}
						title={t('GENERIC.TEXT.PERIOD')}
						options={listMonths}
						handleChange={changeSelectMonth} />
				</Row.Item>

				<Row.Item sm={6} md={6} lg={6}>
					<Select medium
						fullWidth
						name="selectYear"
						value={yearValue.value}
						title={t('GENERIC.TEXT.YEAR')}
						options={listYears}
						handleChange={changeSelectYear} />
				</Row.Item>

				{reportType === 'hourBankBalances' && 
					<Row.Item xs={12} sm={12} md={12} lg={12}>
						<DualList
							titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES')}
							titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES')}
							options={employeesDualList}
							firstIcon="user"
							secondIcon="tools"
							thirdIcon="clock"
							handleChange={(dualListSelected) => {
								if(!!dualListSelected){
									const employeesSelectedList = dualListSelected
										.filter(({ selected }: { selected: boolean }) => selected);

									handleChanges?.({ 
										key: "New.dualList.selected", 
										value: employeesSelectedList
									});

									setEmployeesSelected(employeesSelectedList);
								}
							}} />
					</Row.Item>
				}
			</Row>

			<Container className={styles['actions']}>
				<Button className={styles['action']} 
					title={t('GENERIC.BUTTON.CANCEL.TEXT')}
					icon="times"
					md
					action={(e) => {
						e.preventDefault();
						if (typeof cancelAction === 'function') {
							cancelAction();
						}
					}} />

				<Button className={styles['action']}
					title={t('GENERIC.BUTTON.SAVE.TEXT')}
					icon="save"
					primary 
					md
					loading={loadingButtonSave}
					action={() => saveReport()} />
			</Container>

			{toastAlert.show &&
				<Toast 
					title={t('GENERIC.TEXT.OPERATION')}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}

		</Container>
		
	);
};

export default New;
