import React, { useEffect, useState } from 'react';

// import External Functions
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { downloadCsv, downloadPdf } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import { getSearchParams } from '@optimuminterfaces/revex-react-components/dist/utils/RequestUtil';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

// import Internal Functions
import { fetchAllReports, downloadReport } from '../../../../services/Report.services';
import { translateStatus, translateType } from '../../../../utils/ReportUtil';

// import External Components
import { Button, Loading, Message, Modal, PageTitle, Panel, Row, Select, TextInformation, ButtonDropdown } from '@optimuminterfaces/revex-react-components/dist/components';

// import Internal Components
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import New from '../New/New';

// import Models
import { Employee } from '../../../../models/Employee';
import { Report } from '../../../../models/Report';

import styles from './List.module.scss';

interface ListProps{
	Container?: any
};

const getReportTypeFromQueryParams = ( defaultReportType: String) => {
	const queryParams = getSearchParams();
	if(!!queryParams.type){
		return queryParams.type;
	}

	return defaultReportType;
};

const List = ({Container='div'}: ListProps) => {

	const statuses = [
		{ key: '', value: t('GENERIC.TEXT.ALL'), selected: true },
		{ key: 'AWAIT_PROCESSING', value: t('CABLE.REPORT.TEXT.AWAITING_PROCESSING'), selected: true },
		{ key: 'ERROR', value: t('CABLE.REPORT.TEXT.ERROR'), selected: true },
		{ key: 'PROCESSED', value: t('CABLE.REPORT.TEXT.PROCESSED'), selected: true },
	];

	const [reports, setReports] = useState<Report[]>();
	const [reportStatus, setReportStatus] = useState<string>('waiting');
	const [statusValue, setStatusValue] = useState<any>(statuses[0]);
	const [listStatuses, setListStatuses] = useState(statuses);
	const [filters, setFilters] = useState<{status?: string}>({status: statuses[0].key});
	const [showModalLoading,setShowModalLoading] = useState(false);
	const [showModalNewReport,setShowModalNewReport] = useState(false);
	const [employees, setEmployees] = useState<Employee[]>([]);
	const [queryParams, _] = useState({ reportType: getReportTypeFromQueryParams('pointEvents') });

	useEffect(()=>{
		(async () => {
			setReportStatus('waiting');
			try{
				const jsonReturned =  await fetchAllReports({ 
					...filters, 
					reportType: queryParams.reportType, 
					fetchEmployees: (queryParams.reportType === 'hourBankBalances')
				});

				if(jsonReturned){
					setReports(jsonReturned.reports);
					
					if(!!jsonReturned.employees){
						setEmployees(jsonReturned.employees);
					}

					setReportStatus('success');
				}else{
					setReportStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[filters])

	const changeSelectStatus = (key: string) => {
        let statuses = [...listStatuses];

        for (var i = 0; i < statuses.length; i++) {
            if (statuses[i].key === key) {
                statuses[i].selected = true;
                setStatusValue(statuses[i]);
				setFilters({...filters, status: statuses[i].key})
            } else {
                statuses[i].selected = false;
            }
        }

        setListStatuses(statuses);
    };

	const download = async (report: Report, reportExtension: String) => {
        setShowModalLoading(true);

		const reportExtensionType = reportExtension.toUpperCase();

        let fileName = `${report.name}.${reportExtensionType === 'CSV' ? 'csv' : 'pdf'}`;

		try{
			const jsonReturned =  await downloadReport(report.id, { reportExtension: reportExtensionType });
			if(jsonReturned){
				if(reportExtensionType == 'CSV'){
					downloadCsv(jsonReturned, fileName);
				}else{
					downloadPdf(jsonReturned, fileName);
				}
			}

			setShowModalLoading(false);
		}catch(error){
			console.log(error);
		}
    };

	const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: Report ) => {
		switch(columnName){
			case 'period':
				return columnValue.split(' ').map((element: string, idx: number) => {
					return formatLocalDate(element, true, 'YYYY-MM-DD', undefined)+ (idx===0?' até ':'');
				});
			case 'created':
				return formatLocalDate(columnValue, true, 'yyyy-MM-DDTHH:mm:ss.sss','DD/MM/yyyy HH:mm:ss');
			case 'status':
				return renderStatus(columnValue);
			case 'type':
				return translateType(columnValue);
			case 'actions':
				return (
					<>
						{row.status==='PROCESSED' && 
							// <Button primary sm
							// 	title={t('CABLE.REPORT.TEXT.DOWNLOAD_REPORT')}
							// 	icon="download"
							// 	className={styles['table-item-action']}
							// 	action={() => { download(row) }} />
							<ButtonDropdown
								title={t('GENERIC.TEXT.OPTIONS')}
								icon='cogs'
								primary
								md
								outlined>
								<ButtonDropdown.Session
									title={t('GENERIC.TEXT.REPORTS') + ' PDF'}
									icon='file-pdf'>
									<ButtonDropdown.Session.Item
										title={t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'PDF')}
										action={() => { download(row, 'PDF') }} />
								</ButtonDropdown.Session>

								<ButtonDropdown.Session
									title={t('GENERIC.TEXT.REPORTS') + ' CSV'}
									icon='file-csv'>
									<ButtonDropdown.Session.Item
										title={t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'CSV')}
										action={() => { download(row, 'CSV') }} />
								</ButtonDropdown.Session>
							</ButtonDropdown>
						}
					</>
				)
			default:
				return columnValue;
		}
	};

	const renderStatus = (status: string) => {
		return (
			<Message message={translateStatus(status)}
				danger={status === 'ERROR'}
				success={status === 'PROCESSED'}
				warning={status === 'AWAIT_PROCESSING'}
				icon={status === 'ERROR' ? 'times' : (status === 'PROCESSED' ? 'check' : (status === 'AWAIT_PROCESSING' ? 'clock' : ''))} />
		);
	};

	return (
		<Container className={styles['report-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.REPORT.TEXT.REPORT_LIST')} />
				</Row.Item>
			</Row>

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel noTitleBorder>
						<Row>
							<Row.Item lg={3} md={3} sm={6}>
								<Select name="selectStatus" 
									value={statusValue.value} 
									title={t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')} 
									medium 
									fullWidth 
									options={listStatuses} 
									handleChange={changeSelectStatus} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			<Row>
				<Row.Item lg={12} md={12} sm={12}>
					<Panel noPadding={reportStatus === 'success' && reports!.length > 0}>
						<>
							<Row>
								<Row.Item lg={12} md={12} sm={12}>
									<Button primary md
										title={t('CABLE.REPORT.TEXT.NEW_REPORT')}
										icon="plus"
										className={styles['action']}
										action={() => { setShowModalNewReport(true)}} />
								</Row.Item>
							</Row>

							{(reportStatus === 'waiting') && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<Loading text={t('GENERIC.TEXT.LOADING')}/>
									</Row.Item>
								</Row>
							}

							{(reportStatus === 'error') && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TextInformation 
												icon='exclamation-circle'
												text={t('GENERIC.TEXT.UNABLE_RETURN')} />
									</Row.Item>
								</Row>
							}

							{reports &&  reportStatus === 'success' && reports.length === 0 &&
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TextInformation 
												icon="search"
												text={t('CABLE.REPORT.TEXT.NO_REPORTS_FOUND')} />
									</Row.Item>
								</Row>
							}

							{(reports && reportStatus === 'success') && reports.length > 0 && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TableListPagination list={reports}
											headers={[ 
												t('GENERIC.TEXT.NAME'), 
												t('GENERIC.TEXT.PERIOD'), 
												t('GENERIC.TEXT.STATUS'), 
												t('GENERIC.TEXT.TYPE'), 
												t('CABLE.REPORT.TEXT.MADE_IN'), 
												t('GENERIC.TEXT.OPTIONS')
											]}
											columns={[ 
												'name', 
												'period', 
												'status', 
												'type', 
												'created', 
												'actions' 
											]}
											handleColumnRender={handleColumnRender} />
									</Row.Item>
								</Row>
							}
						</>
					</Panel>
				</Row.Item>
			</Row>

			{ showModalLoading &&
                <Modal title={t('GENERIC.TEXT.LOADING')} 
					xs>
					<Loading text={t('CABLE.REPORT.TEXT.DOWNLOAD_REPORT')} />
                </Modal>
            }

			{ showModalNewReport &&
                <Modal className={styles['modal-new-report']} 
					title={t('CABLE.REPORT.TEXT.SUBMIT_REPORT')} 
					md>
					<New 
						reportType={queryParams.reportType} 
						employees={employees} 
						cancelAction={() => { 
							setShowModalNewReport(false);
						}}
						saveBeforeAction={()=> {
							setShowModalNewReport(false); 
							setFilters({...filters})
						}} />
                </Modal>
            }

		</Container>
	);
};

export default List;
